import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import { customContainer } from '@utils/twin-macro';

import Text from '@components/text';
import Header from '@components/coffee-houses/for-you/header';
import Cms from '@components/coffee-houses/for-you/cms';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative max-w-xs mb-24 pt-10 px-14 text-center
    md:flex md:justify-between md:items-center md:mb-50 md:pt-30 md:text-left
    lg:items-start lg:pt-40 lg:px-0
  `}
  
  @media (min-width: 640px) and (max-width: 767px) {
    max-width: 320px;
  }
`;

const LeftCol = tw.div`md:w-380 lg:w-440 lg:mt-80`;

const RightCol = tw.div`md:w-320 lg:w-500`;

const News = styled(Text)`
  ${tw`mb-28 md:-ml-8 lg:-ml-10`}
  
  br { ${tw`hidden lg:block`} }
`;

const ForYou = () => (
  <Wrapper id="forYou">
    <LeftCol>
      <Header />
    </LeftCol>
    <RightCol>
      <News fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="coffee houses.for you.news" />
      </News>
      <Cms />
    </RightCol>
  </Wrapper>
);

export default ForYou;
