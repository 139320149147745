import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Header from '@components/coffee-houses/header';
import Houses from '@components/coffee-houses/places';
import Menu from '@components/coffee-houses/menu';
import App from '@components/coffee-houses/app';
import ForYou from '@components/coffee-houses/for-you';
import OurInterior from '@components/coffee-houses/our-interior';

const CoffeeHouses = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.coffee houses' })}
        description={intl.formatMessage({ id: 'meta tags.description.coffee houses' })}
      />
      <Header lang={intl.locale} />
      <Houses lang={intl.locale} />
      <Menu />
      <App />
      <ForYou />
      <OurInterior />
    </Layout>
  );
};

export default CoffeeHouses;
