import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import BlockSanity from '@components/block-sanity';

const TextOpacity = tw(Text)`opacity-80`;

const Hours = styled(TextOpacity)`
  ${tw`my-16 xl:my-27`}
  
  b { ${tw`font-600`} }
`;

const OpeningHours = ({ hours, address }) => (
  <>
    <TextOpacity color="white" fontSizes={14} fontWeight={300} lineHeight={1.4} letterSpacing={18}>
      <BlockSanity content={address} noSpacing />
    </TextOpacity>
    <Hours color="white" fontSizes={14} fontWeight={300} lineHeight={1.4} letterSpacing={18}>
      <b><FormattedMessage id="coffee houses.houses.opening hours" /></b>
      {hours.map((hour, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {hour}
        </div>
      ))}
    </Hours>
    <TextOpacity color="white" fontSizes={14} letterSpacing={18} underline uppercase>
      <FormattedMessage id="btn.see more" />
    </TextOpacity>
  </>
);

OpeningHours.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.string).isRequired,
  address: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default OpeningHours;
