import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import Text from '@components/text';
import LinkCoffeeHouse from '@components/link/coffee-house';
import OpeningHours from '@components/coffee-houses/places/opening-hours';
import ImageBorders from '@components/coffee-houses/places/image-borders';
import { useIntl } from 'gatsby-plugin-intl';

const Wrapper = styled.div`
  ${tw`
    relative w-full mb-40 overflow-hidden
    md:w-1/3 md:mb-24 md:px-12
    lg:w-1/4 lg:mb-44 lg:px-8
    xl:px-16
  `}

   @media (hover: none) and (pointer: coarse) {
    ::after {
      content: '';
      ${tw`absolute inset-0 transform transition-transform duration-0 delay-200`}
    }
  
    :hover::after,
    :focus::after {
      ${tw`-translate-x-full`}
    }
  }
  
  @media (min-width: 768px) {
    :nth-of-type(3n + 2) { top: -26px; }

    :nth-of-type(3n + 3) { top: -14px; }
  }
  
  @media (min-width: 1024px) {
    :nth-of-type(4n + 2) { top: -26px; }
  
    :nth-of-type(4n + 3) { top: -9px; }
  
    :nth-of-type(8n + 8) { top: -40px; }
  }
`;

const Image = tw(Img)`h-310`;

const Link = tw(LinkCoffeeHouse)`w-full inline-block`;

const Title = tw(Text)`lg:ml-10 lg:mr-15`;

const Hover = styled.div`
  background-color: rgba(35, 31, 32, 0.74);
  ${tw`flex flex-col justify-center absolute inset-0 opacity-0 transition-opacity duration-400 ease-in-out`}

  ${Wrapper}:hover &,
  ${Wrapper}:focus & {
    ${tw`absolute opacity-100`}
  }
`;

const Place = ({ coffeeHouse }) => {
  const { locale } = useIntl();

  return (
    <Wrapper>
      <Link to={coffeeHouse.slug.current}>
        <ImageBorders>
          <Image fluid={coffeeHouse.collage.asset.fluid} />
          <Hover>
            <OpeningHours
              hours={coffeeHouse[`hours_${locale}`]}
              /* eslint-disable-next-line no-underscore-dangle */
              address={coffeeHouse._rawAddress}
            />
          </Hover>
        </ImageBorders>
        <Title fontFamily="bebasNeue" fontSizes={36} letterSpacing={45}>
          {coffeeHouse[`name_${locale}`]}
        </Title>
      </Link>
    </Wrapper>
  );
};

Place.propTypes = {
  coffeeHouse: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.objectOf(
          PropTypes.objectOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
          ),
        ),
      ),
      PropTypes.arrayOf(PropTypes.any),
    ]),
  ).isRequired,
};

export default Place;
