import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedHTMLMessage, FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Hashtag = tw(Text)`mb-8`;

const ContentWrapper = tw.div`md:mb-30 lg:flex lg:mt-30 lg:mb-4`;

const ContentText = tw(Text)`md:inline lg:block lg:w-430`;

const Subtitle = styled(ContentText)`
  ${tw`lg:mr-140`}
  
  b { ${tw`font-600`} }
  br { ${tw`sm:hidden`} }
`;

const Header = () => (
  <>
    <Hashtag color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="coffee houses.menu.hashtag" />
    </Hashtag>
    <Text fontFamily="bebasNeue" fontSizes={[42, 58]} fontWeight={700} lineHeight={[1.3, 1.1]} letterSpacing={[52, 72]}>
      <FormattedMessage id="coffee houses.menu.title" />
    </Text>
    <ContentWrapper>
      <Subtitle fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="coffee houses.menu.subtitle" />
      </Subtitle>
      <ContentText fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="coffee houses.menu.grab" />
      </ContentText>
    </ContentWrapper>
  </>
);

export default Header;
