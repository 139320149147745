import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import MarkDefault from '@images/mark.svg';

const TitleWrapper = tw.div`relative mb-8 md:inline-block md:mb-0`;

const Title = tw(Text)`relative`;

const Mark = styled(MarkDefault)`
  top: -40px;
  left: -44px;
  height: 154px;
  transform: rotate(8deg);
  ${tw`absolute w-330 md:w-380 lg:w-390`}
  
  .color { fill: #DBA79E; }
  
  @media (min-width: 768px) {
    top: -45px;
    left: ${({ lang }) => (lang === 'en' ? -70 : -40)}px;
    height: 148px;
    transform: rotate(6deg);
  }

  @media (min-width: 1024px) {
    left: ${({ lang }) => (lang === 'en' ? -50 : -10)}px;
    height: 157px;
  }
`;

const Subtitle = tw(Text)`relative mb-6 md:mb-10`;

const Header = ({ lang }) => (
  <>
    <TitleWrapper>
      <Mark lang={lang} />
      <Title fontFamily="bebasNeue" fontSizes={[42, 48]} lineHeight={[1.1, 1.19]} letterSpacing={60}>
        <FormattedMessage id="coffee houses.houses.title" />
      </Title>
    </TitleWrapper>
    <Subtitle fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="coffee houses.houses.subtitle" />
    </Subtitle>
  </>
);

Header.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Header;
