import React from 'react';
import tw from 'twin.macro';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';

import Link from '@components/link';

const MenuImg = tw(Img)`w-full md:w-300 md:-ml-30 md:mr-30 lg:w-600 lg:-ml-60`;

const Pdf = () => {
  const { locale } = useIntl();
  const { menu } = useStaticQuery(graphql`
    query {
      menu: sanityMenu(menu_title: {eq: "Menu"}) {
        file_en {
          asset {
            url
          }
        }
        file_pl {
          asset {
            url
          }
        }
        image_en {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        image_pl {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return (
    <Link to={menu[`file_${locale}`].asset.url} isExternal>
      <MenuImg fluid={menu[`image_${locale}`].asset.fluid} />
    </Link>
  );
};

export default Pdf;
