import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

const Wrapper = tw.div`flex justify-center items-center mt-56 lg:mt-60`;

const DotWrapper = styled.div`
  ${tw`flex justify-center items-center w-20 h-12 cursor-pointer`}

  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(5),
  &:nth-of-type(6),
  &:nth-of-type(9),
  &:nth-of-type(10) {
    ${tw`md:hidden`}
  }

  &:nth-of-type(4),
  &:nth-of-type(8) {
    ${tw`md:hidden`}
  }

  &:nth-of-type(5) {
    ${tw`lg:flex`}
  }
`;

const Dot = styled.div`
  ${tw`w-10 h-10 bg-brownDot opacity-50 rounded-full transition-all duration-300`}
  ${({ active }) => active && tw`opacity-100`}
`;

const Pagination = ({ slides, activeSlide, setActiveSlide }) => (
  <Wrapper>
    {slides.map((post, index) => (
      <DotWrapper
        /* eslint-disable-next-line react/no-array-index-key */
        key={post}
        onClick={() => activeSlide !== index && setActiveSlide(index)}
      >
        <Dot active={activeSlide === index} />
      </DotWrapper>
    ))}
  </Wrapper>
);

Pagination.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default Pagination;
