import React, { useState } from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import SwipeableViews from 'react-swipeable-views';
import Pagination from '@components/coffee-houses/our-interior/pagination';

const slides = [
  'slider1', 'slider2', 'slider3', 'slider4', 'slider5',
  'slider6', 'slider7', 'slider8', 'slider9', 'slider10',
];

const Wrapper = tw.div`md:overflow-hidden lg:max-w-1005 xl:max-w-1105 xl:ml-115`;

const SwipeableViewsWrapper = styled.div`
  left: ${({ firstSlide }) => (firstSlide ? -20 : 0)}px;
  ${tw`relative transition-all duration-300 md:w-310 lg:w-306`}
  
  & > div { ${tw`overflow-visible! px-30`} }
  
  .react-swipeable-view-container > div {
    ${tw`px-10 transition-all duration-300 md:pointer-events-none`}
    
    &:nth-of-type(1) {
      ${({ firstSlide }) => (firstSlide && tw`pl-0 pr-30`)};
      ${tw`md:px-10`}
    }

    &:nth-of-type(2) {
      left: ${({ firstSlide }) => (firstSlide ? -20 : 0)}px;
      ${tw`transition-all duration-300 md:left-0`}
    }
    
    &:nth-of-type(2n) {
      top: 27px;
      ${tw`relative lg:top-0`}
    }
    
    &:nth-of-type(4n + 2) {
      @media (min-width: 1024px) { top: 27px; }
    }
  }

  @media (min-width: 768px) {
    left: -22px;
  }
`;

const Slide = tw.div`h-full`;

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      slider1: file(relativePath: { eq: "coffee-houses/interiors/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider2: file(relativePath: { eq: "coffee-houses/interiors/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider3: file(relativePath: { eq: "coffee-houses/interiors/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider4: file(relativePath: { eq: "coffee-houses/interiors/4.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider5: file(relativePath: { eq: "coffee-houses/interiors/5.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider6: file(relativePath: { eq: "coffee-houses/interiors/6.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider7: file(relativePath: { eq: "coffee-houses/interiors/7.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider8: file(relativePath: { eq: "coffee-houses/interiors/8.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider9: file(relativePath: { eq: "coffee-houses/interiors/9.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      slider10: file(relativePath: { eq: "coffee-houses/interiors/10.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClickedSlide = (e, index) => {
    e.preventDefault();
    setActiveSlide(index);
  };

  return (
    <Wrapper>
      <SwipeableViewsWrapper firstSlide={activeSlide === 0}>
        <SwipeableViews
          index={activeSlide}
          onChangeIndex={setActiveSlide}
        >
          {slides.map((slide, index) => (
            <Slide
              key={slide}
              onClick={(e) => activeSlide !== index && handleClickedSlide(e, index)}
            >
              <Img fluid={data[slide].childImageSharp.fluid} />
            </Slide>
          ))}
        </SwipeableViews>
      </SwipeableViewsWrapper>
      <Pagination slides={slides} setActiveSlide={setActiveSlide} activeSlide={activeSlide} />
    </Wrapper>
  );
};

export default Slider;
