import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Images from '@components/coffee-houses/places/filters/images';
import Header from '@components/coffee-houses/places/filters/header';
import Options from '@components/coffee-houses/places/filters/options';
import Buttons from '@components/coffee-houses/places/filters/buttons';

const Wrapper = styled.div`
  top: -80px;
  ${tw`relative -mb-40 bg-pink overflow-hidden md:mb-0`}
  
  @media (min-width: 768px) { top: -60px; }
  @media (min-width: 1024px) { top: -85px; }
`;

const Container = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 pt-100 px-24 pb-180 text-center
    md:w-auto md:pt-120 md:pb-100
    lg:pt-230 lg:px-0 lg:pb-110
  `}
`;

const Index = tw.div`relative`;

const Filters = ({ lang, handleFilter }) => {
  const [active, handleActive] = useState(false);

  const handleButtons = (val) => {
    if (!val) handleActive(val);
    handleFilter(val ? active : false);
  };

  return (
    <Wrapper>
      <Container>
        <Images />
        <Index>
          <Header lang={lang} />
          <Options active={active} handleActive={handleActive} />
          <Buttons handleFilter={handleButtons} />
        </Index>
      </Container>
    </Wrapper>
  );
};

Filters.propTypes = {
  lang: PropTypes.string.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default Filters;
