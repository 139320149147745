import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';

const Wrapper = tw.div`flex flex-col justify-center items-center mt-20 sm:flex-row`;

const Button = tw(ButtonDefault)`mt-0 mb-25 py-10 px-40 sm:mb-0 sm:mr-30 lg:px-50`;

const ButtonClear = tw(Text)`cursor-pointer`;

const Buttons = ({ handleFilter }) => (
  <Wrapper>
    <Button onClick={() => handleFilter(true)}>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="btn.filter" />
      </Text>
    </Button>
    <ButtonClear
      fontSizes={12}
      letterSpacing={15}
      uppercase
      underline
      onClick={() => handleFilter(false)}
    >
      <FormattedMessage id="btn.clean filter" />
    </ButtonClear>
  </Wrapper>
);

Buttons.propTypes = {
  handleFilter: PropTypes.func.isRequired,
};

export default Buttons;
