import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';

import Text from '@components/text';
import Img from 'gatsby-image';

const Wrapper = styled.div`
  left: 10px;
  ${tw`relative flex flex-wrap max-w-xs text-left md:flex-col md:items-center md:max-w-none md:h-80 md:mx-auto`}
  
  @media (min-width: 768px) { left: 20px; }
`;

const Filter = styled.div`
  ${tw`relative flex items-center w-1/2 my-6 cursor-pointer md:w-130 md:my-8`}
  
  ::after {
    content: '';
    left: 4px;
    // don't use tailwind for this transform -> problem with transition on Safari browser
    transform: scale(0) translateY(-50%);
    ${tw`absolute top-1/2 w-5 h-5 bg-brown rounded-full transition-all duration-300`}
  }
  
  &.active::after {
    transform: scale(1) translateY(-50%);
  }
`;

const Image = tw(Img)`w-12 mr-10`;

const Label = styled(Text)`
  ${Filter}.active & {
    ${tw`font-600`}
  }
`;

const Options = ({ active, handleActive }) => {
  const { locale } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cities :allSanityCity(sort: {fields: _createdAt}) {
        nodes {
          name_en
          name_pl
        }
      }
    }
  `);

  return (
    <Wrapper style={{ width: `${((data.cities.nodes.length) / 2) * 154}px` }}>
      {data.cities.nodes.map((city) => (
        <Filter
          key={city.name_en}
          onClick={() => handleActive(city.name_en)}
          className={active === city.name_en ? 'active' : ''}
        >
          <Image fluid={data.mark.childImageSharp.fluid} />
          <Label fontSizes={[14, 16]} lineHeight={[1.71, 1.5]} letterSpacing={[30, 34]} uppercase>
            { city[`name_${locale}`] }
          </Label>
        </Filter>
      ))}
    </Wrapper>
  );
};

Options.propTypes = {
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  handleActive: PropTypes.func.isRequired,
};

export default Options;
