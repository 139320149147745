import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Link from '@components/link';
import { navigationRules } from '@utils/navigation';

const Terms = styled(Text)`
  ${tw`hidden md:inline-block`}
  
  b { ${tw`underline`} }
`;

const CTA = () => (
  <Link to={navigationRules.path} isExternal={navigationRules.externalLink}>
    <Terms fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee houses.for you.terms" />
    </Terms>
  </Link>
);

export default CTA;
