import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-intl';
import { capitalizeFirstLetter } from '@utils/helpers';
import { graphql, useStaticQuery } from 'gatsby';

import Link from '@components/link';

const Wrapper = tw.div`flex mt-26`;

const GoogleLink = tw(Link)`mr-25`;

const Logo = styled(Img)`
  ${tw`w-135`}

  & > div {
    padding-bottom: 33.6% !important;
  }
`;

const LogoWider = styled(Img)`
  ${tw`w-148`}

  & > div {
    padding-bottom: ${({ lang }) => (lang === 'en' ? 30 : 29.5)}% !important;
  }
`;

const Stores = () => {
  const { locale } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      appStorePl: file(relativePath: { eq: "coffee-houses/app-store-pl.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appStoreEn: file(relativePath: { eq: "coffee-houses/app-store-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      googlePlayPl: file(relativePath: { eq: "coffee-houses/google-play-pl.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      googlePlayEn: file(relativePath: { eq: "coffee-houses/google-play-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <GoogleLink to="https://play.google.com/store/apps/details?id=pl.loyaltyclub.etnocafe" isExternal>
        <LogoWider lang={locale} fluid={data[`googlePlay${capitalizeFirstLetter(locale)}`].childImageSharp.fluid} />
      </GoogleLink>
      <Link to="https://apps.apple.com/pl/app/etno-cafe/id6698893092?l=pl" isExternal>
        <Logo fluid={data[`appStore${capitalizeFirstLetter(locale)}`].childImageSharp.fluid} />
      </Link>
    </Wrapper>
  );
};

export default Stores;
