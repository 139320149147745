import React from 'react';
import tw, { styled } from 'twin.macro';

const Triangle = tw.div`absolute w-0 h-0`;

const TrianglePink = styled(Triangle)`
  top: 0;
  left: -160px;
  border-top: 615px solid #f2c1b6;
  border-right: 450px solid transparent;
  
  @media (min-width: 640px) {
    left: -230px;
    border-top-width: 710px;
    border-right-width: 520px;
  }

  @media (min-width: 768px) {
    top: 440px;
    border-top-width: 480px;
  }
  
  @media (min-width: 1024px) {
    top: 490px;
  }
  
  @media (min-width: 1280px) {
    left: -410px;
    border-top-width: 980px;
    border-right-width: 670px;
  }

  @media (min-width: 1921px) {
    left: -730px;
    border-top-width: 1470px;
    border-right-width: 990px;
  }
`;

const TriangleBlue = styled(Triangle)`
  bottom: 0;
  right: -160px;
  border-bottom: 600px solid #D7EFFD;
  border-left: 580px solid transparent;
  
  @media (min-width: 640px) {
    right: -230px;
    border-bottom-width: 675px;
    border-left-width: 650px;
  }

  @media (min-width: 768px) {
    bottom: -80px;
    right: -270px;
    border-bottom: initial;
    border-top: 675px solid #D7EFFD;
    border-left-width: 650px;
  }
  
  @media (min-width: 1024px) {
    bottom: initial;
    top: 80px;
    right: -140px;
    border-top-width: 795px;
    border-left-width: 740px;
  }

  @media (min-width: 1280px) {
    right: -410px;
    border-top-width: 1050px;
    border-left-width: 1005px;
  }
  
  @media (min-width: 1921px) {
    right: -730px;
    border-top-width: 1385px;
    border-left-width: 1330px;
  }
`;

const Triangles = () => (
  <>
    <TrianglePink />
    <TriangleBlue />
  </>
);

export default Triangles;
