import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/coffee-houses/menu/header';
import Pdf from '@components/coffee-houses/menu/pdf';
import Options from '@components/coffee-houses/menu/options';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative max-w-xs mb-80 px-14 text-center
    sm:mb-0
    md:text-left
    lg:pt-40 lg:px-0
  `}
  
  @media (min-width: 640px) and (max-width: 767px) { max-width: 470px; }
`;

const Information = tw.div`
  -mx-25
  md:flex md:justify-between md:items-center md:mx-0
  lg:justify-start
`;

const Menu = () => (
  <Wrapper id="menu">
    <Header />
    <Information>
      <Pdf />
      <Options />
    </Information>
  </Wrapper>
);

export default Menu;
