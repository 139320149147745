import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`-mx-10 mb-20 md:mx-0 lg:mb-25`;

const ColWrapper = tw.div`md:flex lg:justify-between`;

const ColLeft = tw(Text)`mb-32 md:mr-10 lg:w-420 lg:mr-0 xl:w-535`;

const ColRight = tw(Text)`mb-50 lg:w-540 lg:mb-58 xl:w-535`;

const Mark = styled(Img)`
  top: 30px;
  right: -110px;
  ${tw`absolute! hidden md:block md:w-160 lg:w-230`}
  
  @media (min-width: 1024px) {
    top: 62px;
    right: -256px;
  }
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Mark fluid={data.mark.childImageSharp.fluid} />
      <Title fontFamily="bebasNeue" fontWeight={300} fontSizes={[42, 45]} lineHeight={[1.14, 1.2]} letterSpacing={[52, 56]}>
        <FormattedHTMLMessage id="coffee houses.our interior.title" />
      </Title>
      <ColWrapper>
        <ColLeft fontSizes={16} lineHeight={1.5} letterSpacing={20}>
          <FormattedHTMLMessage id="coffee houses.our interior.left col" />
        </ColLeft>
        <ColRight fontSizes={16} lineHeight={1.5} letterSpacing={20}>
          <FormattedHTMLMessage id="coffee houses.our interior.right col" />
        </ColRight>
      </ColWrapper>
    </>
  );
};

export default Header;
