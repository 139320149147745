import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import CTA from '@components/coffee-houses/header/cta';

const Wrapper = tw.div`relative`;

const Hashtag = tw(Text)`mb-12 lg:mb-6`;

const Title = styled(Text)`
  ${tw`mb-15 md:mb-24`}
  
  b { ${tw`tracking-107 lg:tracking-138`} }
`;

const Subtitle = styled(Text)`
  b { ${tw`font-600`} }
`;

const Content = () => (
  <Wrapper>
    <Hashtag fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="coffee houses.header.hashtag" />
    </Hashtag>
    <Title fontFamily="bebasNeue" fontSizes={[42, 54]} fontWeight={300} lineHeight={[1, 1.3]} letterSpacing={[114, 146]}>
      <FormattedHTMLMessage id="coffee houses.header.title" />
    </Title>
    <Subtitle fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee houses.header.subtitle" />
    </Subtitle>
    <CTA />
  </Wrapper>
);

export default Content;
