import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import Path from '@images/coffee-house.svg';

const StyledPath = styled(Path)`
  top: -70px;
  right: -380px;
  height: 1180px;
  ${tw`hidden w-490 lg:block absolute`}
  
  @media (min-width: 1280px) { right: -420px; }
  
  @media (min-width: 1921px) {
    top: -260px;
    right: -730px;
    width: 840px;
    height: 1500px;
  }
`;

const Mark = styled(Img)`
  top: 408px;
  left: -48px;
  width: 138px;
  ${tw`absolute!`}
  
  @media (min-width: 768px) {
    top: 250px;
    left: -70px;
  }
  
  @media (min-width: 1024px) {
    top: 300px;
    left: -70px;
    width: 177px;
  }
  @media (min-width: 1280px) {
    left: -90px;
  }
`;

const Beans = styled(Img)`
  left: -98px;
  ${tw`absolute! top-0 w-160`}
  
  @media (min-width: 768px) { top: 60px; }

  @media (min-width: 1024px) {
    top: -35px;
    left: initial;
    right: -95px;
    width: 172px;
  }
  @media (min-width: 1280px) { right: -154px; }
`;

const BeansSmall = styled(Img)`
  top: 435px;
  right: -50px;
  ${tw`absolute! w-90 lg:left-0`}
  
  @media (min-width: 768px) {
    top: 260px;
    right: -10px;
  }

  @media (min-width: 1024px) {
    top: 80px;
    right: initial;
    width: 82px;
  }

  @media (min-width: 1280px) {
    top: 111px;
    left: -130px;
  }
`;

const BeansOne = styled(Img)`
  top: 248px;
  right: 20px;
  ${tw`absolute! w-36`}
  
  @media (min-width: 768px) {
    top: 390px;
    left: 110px;
    right: initial;
  }
  
  @media (min-width: 1024px) {
    top: 525px;
    left: 180px;
  }

  @media (min-width: 1280px) { left: 100px; }
`;

const Main = styled(Img)`
  top: 382px;
  right: -300px;
  width: 570px;
  ${tw`absolute! hidden lg:block`}
  
  @media (min-width: 1280px) {
    top: 300px;
    right: ${({ lang }) => (lang === 'en' ? -440 : -420)}px;
    width: 681px;
  }
`;

const Images = ({ lang }) => {
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beans: file(relativePath: { eq: "coffee-beans.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beansSmall: file(relativePath: { eq: "coffee-beans-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beansOne: file(relativePath: { eq: "coffee-beans-one.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      main: file(relativePath: { eq: "coffee-houses/main.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <>
      <StyledPath />
      <Mark fluid={data.mark.childImageSharp.fluid} />
      <Beans fluid={data.beans.childImageSharp.fluid} />
      <BeansSmall fluid={data.beansSmall.childImageSharp.fluid} />
      <BeansOne fluid={data.beansOne.childImageSharp.fluid} />
      <Main lang={lang} fluid={data.main.childImageSharp.fluid} />
    </>
  );
};

Images.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Images;
