import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import CTA from '@components/coffee-houses/for-you/cta';

const Hashtag = tw(Text)`mb-8 lg:mb-6`;

const Title = tw(Text)`mb-16 lg:mb-18`;

const Subtitle = tw(Text)`mb-10 lg:mb-18`;

const Content = tw(Text)`mb-16 lg:mb-0`;

const Header = () => (
  <>
    <Hashtag color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="coffee houses.for you.hashtag" />
    </Hashtag>
    <Title fontFamily="bebasNeue" fontSizes={[42, 58]} fontWeight={700} lineHeight={[1.1, 1.08]} letterSpacing={[52, 72]}>
      <FormattedMessage id="coffee houses.for you.title" />
    </Title>
    <Subtitle fontSizes={16} lineHeight={1.5} fontWeight={600} letterSpacing={20}>
      <FormattedMessage id="coffee houses.for you.subtitle" />
    </Subtitle>
    <Content fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="coffee houses.for you.content" />
    </Content>
  </>
);

export default Header;
