import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Wrapper = tw.div`xl:ml-85`;

const Screen = styled(Img)`
  top: 122px;
  ${tw`
    absolute! left-1/2 w-188 transform -translate-x-1/2
    md:relative! md:top-0 md:left-0 md:w-320 md:transform-none
  `}
  
  @media (min-width: 1024px) { width: 354px; }
`;

const Mark = styled(Img)`
  top: 340px;
  left: 26px;
  ${tw`absolute! w-120 md:w-230`}
  
  @media (min-width: 768px) {
    top: 370px;
    left: -110px;
  }
  
  @media (min-width: 1024px) { top: 415px; }

  @media (min-width: 1024px) { left: -50px; }
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      app: file(relativePath: { eq: "coffee-houses/app.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      coffeeMark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Mark fluid={data.coffeeMark.childImageSharp.fluid} />
      <Screen fluid={data.app.childImageSharp.fluid} />
    </Wrapper>
  );
};

export default Images;
