import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const options = ['sweet', 'salty', 'vege'];

const Wrapper = tw.div`hidden md:block`;

const Option = tw.div`mb-20 lg:w-440`;

const Header = tw.div`md:flex md:items-center lg:mb-12`;

const Image = styled(Img)`
  min-width: 17px;
  ${tw`w-17 h-17 mr-15`}
`;

const Options = () => {
  const data = useStaticQuery(graphql`
    query {
      listItem: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      {options.map((item) => (
        <Option key={item}>
          <Header>
            <Image fluid={data.listItem.childImageSharp.fluid} />
            <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
              <FormattedMessage id={`coffee houses.menu.menu.${item}.title`} />
            </Text>
          </Header>
          <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
            <FormattedHTMLMessage id={`coffee houses.menu.menu.${item}.content`} />
          </Text>
        </Option>
      ))}
    </Wrapper>
  );
};

export default Options;
