import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/coffee-houses/our-interior/header';
import Slider from '@components/coffee-houses/our-interior/slider';

const Background = styled.div`
  background-image: linear-gradient(to bottom, #FAF3EB 700px, #FFF 700px);
  ${tw`relative`}
  
  @media (min-width: 768px) {
    background-image: linear-gradient(to bottom, #FAF3EB 300px, #FFF 300px);
  }
`;

const BackgroundMask = styled.div`
  top: 500px;
  left: -80px;
  right: -80px;
  transform: rotate(-164deg);
  ${tw`absolute h-360 bg-beige`}
  
  @media (min-width: 768px) {
    top: 220px;
    transform: rotate(-172deg);
  }
  
  @media (min-width: 1024px) { top: 140px; }

  @media (min-width: 1921px) { transform: rotate(-175deg); }
`;

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative max-w-xs py-40 px-10 text-center
    md:text-left
    lg:px-0
  `}

  @media (min-width: 640px) and (max-width: 767px) { max-width: 320px; }
`;

const OurInterior = () => (
  <Background>
    <BackgroundMask />
    <Wrapper>
      <Header />
      <Slider />
    </Wrapper>
  </Background>
);

export default OurInterior;
