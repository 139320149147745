import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';

import CmsItem from '@components/coffee-houses/for-you/cms-item';

const Cms = () => {
  const { locale } = useIntl();
  const { boxFirst, boxSecond, boxThird } = useStaticQuery(graphql`
    query {
      boxFirst: sanityPromotions(promotion: {eq: "Pierwszy box"}) {
        promotion
        link_en
        link_pl
        name_en
        name_pl
        image_pl {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        image_en {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      },
      boxSecond: sanityPromotions(promotion: {eq: "Drugi box"}) {
        promotion
        link_en
        link_pl
        name_en
        name_pl
        image_pl {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        image_en {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      },
      boxThird: sanityPromotions(promotion: {eq: "Trzeci box"}) {
        promotion
        link_en
        link_pl
        name_en
        name_pl
        image_pl {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        image_en {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      {[boxFirst, boxSecond, boxThird].map((box) => (
        <CmsItem
          key={box.promotion}
          promotion={box.promotion}
          link={box[`link_${locale}`]}
          name={box[`name_${locale}`]}
          image={box[`image_${locale}`]}
        />
      ))}
    </div>
  );
};

export default Cms;
