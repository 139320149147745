import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

const getCoffeeHousePath = (to, lang) => {
  if (lang === 'pl') return `/kawiarnie/${to}`;

  return `/en/coffee-shops/${to}`;
};

const LinkCoffeeHouse = ({
  children, to, ...props
}) => {
  const intl = useIntl();

  return (
    <GatsbyLink to={getCoffeeHousePath(to, intl.locale)} {...props}>
      {children}
    </GatsbyLink>
  );
};

LinkCoffeeHouse.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

LinkCoffeeHouse.defaultProps = {
  children: '',
  to: '',
};

export default LinkCoffeeHouse;
