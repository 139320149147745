import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Images from '@components/coffee-houses/header/images';
import Content from '@components/coffee-houses/header/content';

const Background = styled.div`
  top: -80px;
  background-image: linear-gradient(to bottom, #FAF3EB 500px, #FFF 500px);
  ${tw`relative pt-80 z-1 md:top-0 md:pt-0 md:bg-none md:bg-beige`}
`;

const Mask = styled.div`
  top: 375px;
  left: -80px;
  right: -80px;
  transform: rotate(-172deg);
  ${tw`absolute h-360 bg-beige`}
  
  @media (min-width: 640px) {
    transform: rotate(-174deg);
  }

  @media (min-width: 768px) {
    top: 125px;
    transform: rotate(-176deg);
  }

  @media (min-width: 1024px) {
    top: 320px;
    transform: rotate(-173deg)
  }

  @media (min-width: 1280px) {
    top: 282px;
  }
`;

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative max-w-xs pt-36 pb-10 text-center
    md:mb-60 md:py-60
    lg:mb-85 lg:pt-86 lg:px-0 lg:pb-90
  `}
  ${({ lang }) => (lang === 'en' ? tw`px-6` : tw`px-10`)}

  @media (min-width: 640px) and (max-width: 767px) {
    max-width: 320px;
  }
`;

const Header = ({ lang }) => (
  <Background>
    <Mask />
    <Wrapper lang={lang}>
      <Images lang={lang} />
      <Content />
    </Wrapper>
  </Background>
);

Header.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Header;
