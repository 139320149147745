import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import AnimateHeight from 'react-animate-height';
import { graphql, useStaticQuery } from 'gatsby';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { currentBreakpoint, debounce } from '@utils/helpers';

import Text from '@components/text';
import Place from '@components/coffee-houses/places/place';
import Filters from '@components/coffee-houses/places/filters';

const handleHeightComponent = (width) => {
  const steps = {
    xs: 1200, md: 970, lg: 1000, xl: 1000,
  };

  return steps[currentBreakpoint(width)];
};

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative max-w-xs px-10 text-center
    lg:px-0
  `}
  
  @media (min-width: 640px) and (max-width: 767px) { max-width: 320px; }
`;

const Animated = styled(AnimateHeight)`
  ${tw`relative md:mb-40 lg:mb-10`}
  
  @media (min-width: 768px) { top: -60px; }
  
  @media (min-width: 1024px) { top: -85px; }
`;

const LateralMargins = tw.div`md:flex md:flex-wrap md:mt-60 md:-mx-12 lg:mt-85 lg:-mx-8 xl:-mx-16`;

const Gradient = styled.div`
  left: -50px;
  right: -50px;
  bottom: -80px;
  height: 452px;
  transition: opacity .5s, left 0s .5s;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 64%);
  ${tw`absolute md:hidden`}

  ${Wrapper}.hide & {
    ${tw`-left-full right-auto opacity-0`}
  }
`;

const ShowMore = styled(Text)`
  transition: opacity .5s, left 0s .5s;
  ${tw`absolute left-1/2 bottom-0 mb-100 pb-2 text-center cursor-pointer transform -translate-x-1/2 md:mb-30 lg:mb-40`}
  
  ${Wrapper}.hide & {
    ${tw`-left-full opacity-0`}
  }
`;

const Houses = ({ lang }) => {
  const { coffeeHouses } = useStaticQuery(graphql`
    query {
      coffeeHouses: allSanityCoffeeHouse {
        nodes {
          _rawAddress
          name_pl
          name_en
          slug {
            current
          }
          hours_en
          hours_pl
          city {
            name_en
          }
          collage {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  const windowInnerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const [filter, handleFilter] = useState(false);
  const [isFullHeight, handleHeight] = useState(false);
  const [viewWidth, setWidth] = useState(windowInnerWidth);
  const [height, setHeight] = useState(1000);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    // eslint-disable-next-line no-unused-vars
    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    setHeight(handleHeightComponent(viewWidth));
  }, [viewWidth]);

  return (
    <>
      <Filters lang={lang} filter={filter} handleFilter={handleFilter} />
      <Wrapper className={isFullHeight || filter || coffeeHouses.nodes.length < 8 ? 'hide' : ''}>
        <Animated
          duration={800}
          height={isFullHeight || filter || coffeeHouses.nodes.length < 8 ? 'auto' : height}
        >
          <LateralMargins>
            {coffeeHouses.nodes
              .filter(({ city }) => (filter ? city.name_en === filter : city))
              .map((coffeeHouse) => (
                <Place key={coffeeHouse.slug.current} coffeeHouse={coffeeHouse} />
              ))}
          </LateralMargins>
        </Animated>
        <Gradient />
        <ShowMore
          uppercase
          underline
          fontSizes={[16, 18]}
          fontWeight={600}
          letterSpacing={[20, 22]}
          onClick={() => handleHeight(true)}
        >
          <FormattedMessage id="btn.show more" />
        </ShowMore>
      </Wrapper>
    </>
  );
};

Houses.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Houses;
