import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

const ImageWrapper = tw.div`mb-10 pt-10 pr-15 pb-16 pl-10 border-2 border-pink`;

const ImageWrapper2 = tw.div`relative border-2 border-pink`;

const ImageBorders = ({ children }) => (
  <ImageWrapper>
    <ImageWrapper2>
      {children}
    </ImageWrapper2>
  </ImageWrapper>
);

ImageBorders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ImageBorders;
