import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Wrapper = tw.div`inline-block mt-48 md:mt-24`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-32`;

const CTA = () => (
  <Wrapper onClick={() => scrollTo('#forYou')}>
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="btn.promotion" />
      </Text>
    </Button>
  </Wrapper>
);

export default CTA;
