import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Map = styled(Img)`
  left: -460px;
  transform: rotate(90deg);
  ${tw`absolute! w-1280 h-640 sm:transform-none md:w-1024 md:h-540 lg:h-630 xl:h-auto`}
  
  @media (min-width: 640px) { bottom: -50px; }

  @media (min-width: 768px) {
    left: -130px;
    bottom: -40px;
  }

  @media (min-width: 1024px) {
    left: -430px;
    bottom: -45px;
    width: 1620px;
  }

  @media (min-width: 1921px) {
    left: -760px;
    bottom: -155px;
    width: 1940px;
  }
`;

const MaskHeader = styled.div`
  top: -20px;
  left: 1090px;
  transform: rotate(-32deg);
  ${tw`hidden lg:block lg:absolute lg:w-370 lg:h-680 lg:bg-beige`}
  
  @media (min-width: 1921px) {
    top: -50px;
    left: 1120px;
    width: 450px;
    height: 800px;
  }
`;

const Hands = styled(Img)`
  bottom: -55px;
  left: 10px;
  transform: rotate(-90deg);
  ${tw`absolute! w-275 md:w-250 md:transform-none lg:w-350`}
  
  @media (min-width: 640px) {
    left: -60px;
  }

  @media (min-width: 768px) {
    bottom: 50px;
    left: -130px;
  }

  @media (min-width: 1024px) {
    bottom: 40px;
    left: -140px;
  }

  @media (min-width: 1280px) {
    bottom: 30px;
    left: -170px;
    width: 394px;
  }
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "hands.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      map: file(relativePath: { eq: "etno-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <>
      <Map fluid={data.map.childImageSharp.fluid} />
      <MaskHeader />
      <Hands fluid={data.main.childImageSharp.fluid} />
    </>
  );
};

export default Images;
