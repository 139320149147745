import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import LinkDefault from '@components/link';

import EggsShadow from '@images/coffee-houses/eggs-shadow.svg';
import SandwichShadow from '@images/coffee-houses/sandwich-shadow.svg';

const Link = styled(LinkDefault)`
  ${tw`
    relative flex items-center w-full mb-16 py-15 pl-12 pr-8 text-left
    md:-ml-15
    lg:py-8 lg:mb-50
  `}
  
  &:nth-of-type(2) { ${tw`pr-16 md:ml-5 md:pr-18 lg:pr-40 xl:ml-40`} }

  &:hover svg { ${tw`opacity-45`} }
`;

const Image = styled(Img)`
  min-width: 110px;
  ${tw`w-110 h-56 mr-10 bg-blue lg:w-180 lg:h-110 lg:mr-30 xl:w-224`}
  
  @media (min-width: 1024px) { min-width: 180px; }
  @media (min-width: 1280px) { min-width: 224px; }
`;

const CTA = tw(Text)`hidden lg:block lg:mt-12`;

const shadowStyles = css`
  top: -10px;
  left: -11px;
  width: calc(100% + 20px);
  height: calc(100% + 22px);
  ${tw`absolute opacity-100 transition-opacity duration-300 -z-1`}
  
  @media (min-width: 1280px) {
    top: -15px;
    left: -17px;
    width: calc(100% + 33px);
    height: calc(100% + 33px);
  }
`;

const ShadowSandwich = styled(SandwichShadow)`${shadowStyles}`;

const ShadowEggs = styled(EggsShadow)`${shadowStyles}`;

const CmsItem = ({
  promotion, name, link, image,
}) => (
  <Link to={link} isExternal>
    {promotion === 'Drugi box' ? <ShadowEggs /> : <ShadowSandwich />}
    <Image fluid={image.asset.fluid} />
    <div>
      <Text fontFamily="bebasNeue" fontSizes={[23, 32]} lineHeight={1.08} letterSpacing={[30, 40]} uppercase>
        {name}
      </Text>
      <CTA fontSizes={14} letterSpacing={18} uppercase underline>
        <FormattedMessage id="btn.see more" />
      </CTA>
    </div>
  </Link>
);

CmsItem.propTypes = {
  promotion: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ),
  ).isRequired,
};

export default CmsItem;
