import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Stores from '@components/coffee-houses/app/stores';

const Content = tw.div`relative md:w-320 md:ml-100 lg:w-535 xl:ml-140`;

const Title = tw(Text)`mb-433 md:mb-28`;

const Subtitle = styled(Text)`
  b { ${tw`font-600`} }
`;

const Header = () => (
  <Content>
    <Title fontFamily="bebasNeue" fontSizes={[42, 58]} fontWeight={700} lineHeight={[1.5, 1.09]} letterSpacing={[52, 72]}>
      <FormattedMessage id="coffee houses.app.title" />
    </Title>
    <Subtitle fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="coffee houses.app.subtitle" />
    </Subtitle>
    <Stores />
  </Content>
);

export default Header;
