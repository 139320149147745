import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Triangles from '@components/coffee-houses/app/triangles';
import Images from '@components/coffee-houses/app/images';
import Header from '@components/coffee-houses/app/header';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative max-w-xs mb-18 pt-30 px-14 pb-86 text-center
    sm:mt-20
    md:flex md:items-center md:mt-40 md:mb-50 md:py-0 md:text-left
    lg:mt-15 lg:mb-120 lg:px-0
  `}
  
  @media (min-width: 640px) and (max-width: 767px) {
    max-width: 320px;
  }
`;

const App = () => (
  <Wrapper id="app">
    <Triangles />
    <Images />
    <Header />
  </Wrapper>
);

export default App;
